import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

const QGroupQuery = graphql`
  query QGroupQuery($groupId: ID!) {
    group(groupId: $groupId) {
      id
      members {
        userId
      }
      name
      roles {
        itemId
        itemType
        roles
      }
    }
  }
`;

const QGroup = ({
  children,
  args,
  childDataProp
}) => (
  <Query
    query={QGroupQuery}
    args={args}
  >
    {populateChildren('group')(children, childDataProp)}
  </Query>
);

export default QGroup;
