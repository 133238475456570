import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QFoldersQuery = graphql`
  query QFoldersQuery($scope: DocumentScopeEnum!, $offerId: ID, $teamId: ID) {
    folders(scope: $scope, offerId: $offerId, teamId: $teamId) {
      id
      name
      parent
      element {
        elmId
        type
      }
      type
    }
  }
`;

const QFolders = ({
  children,
  childDataProp,
  args
}) => {
  return (
    <Query
      query={QFoldersQuery}
      args={args}
    >
      {populateChildren('folders')(children, childDataProp)}
    </Query>
  );
};

export default QFolders;
