import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QOfferQuery = graphql`
  query QOfferQuery($offerId: ID!) {
    offer(offerId: $offerId) {
      id
      offerName
      offerId
      team
      dates {
        creation
        lastUpdate
      }
      version {
        major
        minor
        status
        itemScope
        itemType
        itemRootId
        versionId
        itemId
        isLastMajor
        isLastMinor
        reviewComments {
          comments
          rejectionReason
          status
        }
      }
    }
  }
`;

const QOffer = ({
  children,
  args,
  childDataProp
}) => (
  <Query
    query={QOfferQuery}
    args={args}
  >
    {populateChildren('offer')(children, childDataProp)}
  </Query>
);

export default QOffer;
