import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

const QOfferCommentsQuery = graphql`
  query QOfferCommentsQuery($offerId: ID!) {
    offerComments(offerId: $offerId) {
      itemId
      type
      name
      comments {
        id
        itemKey
        itemPath
        itemRootId
        status
        teamId
        messages {
          text
          userId
          userName
          when
        }
      }
    }
  }
`;

const QOfferComments = ({
  children,
  childDataProp,
  args
}) => (
  <Query
    query={QOfferCommentsQuery}
    args={args}
  >
    {populateChildren('offerComments')(children, childDataProp)}
  </Query>
);

export default QOfferComments;
