import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../.';

const QTeamActionsQuery = graphql`
  query QTeamActionsQuery($pagination: PaginationInput) {
    teamActions(pagination: $pagination) {
      docs {
        user {
          email
        }
        team {
          name
        }
        actionName
        when
        data {
          offer {
            offerNumber
          }
        }
      } 
      pagination {
        page
        pages
        limit
        total
      }  
    }
  }
`;

const QTeamActions = ({
  page,
  limit,
  children
}) => (
  <Query
    query={QTeamActionsQuery}
    args={{
      pagination: {
        page,
        limit
      }
    }}
  >
    {children}
  </Query>
);

QTeamActions.defaultProps = { limit: 25 };

export default QTeamActions;
