import { isNil, prop } from 'ramda';
import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

const QOfferRightsQuery = graphql`
  query QOfferRightsQuery($offerId: ID!) {
    offerRights(offerId: $offerId) {
      userIsOfferValidator
    }
  }
`;

const QOfferRights = ({
  children,
  args,
  childDataProp
}) => {
  if (isNil(prop('offerId', args))) {
    return populateChildren()(children)({});
  }

  return (
    <Query
      query={QOfferRightsQuery}
      args={args}
    >
      {populateChildren('offerRights')(children, childDataProp)}
    </Query>
  );
};

export default QOfferRights;
