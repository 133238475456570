import { CloseOutlined, DeleteOutlined, EyeOutlined, FormOutlined, TagsOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Popover, Row, Space, Tooltip } from 'antd';
import { allPass, always, and, anyPass, compose, cond, defaultTo, equals, filter, find, includes, isNil, path, pathEq, prop, propEq, propOr, propSatisfies } from 'ramda';
import { isNotNil, notEqual, propNotEq } from 'ramda-adjunct';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import CommentsWrapper from '../../../../components/CommentsWrapper';
import hideComments from '../../../../components/commentsWrapper/core/hideComments';
import { useTreeContext } from '../../../../components/treeStructure/treeContext';
import useComments from '../../../../hooks/useComments';
import useDocument from '../../../../hooks/useDocument';
import useDocumentsTable from '../../../../hooks/useDocumentsTable';
import QComments from '../../../../_graphql/queries/comments/QComments';
import ModalPreviewDocument from '../../../docs/components/document/ModalPreviewDocument';
import SelectTags from '../../../docs/components/document/SelectTags';
import { renderDate } from '../../../docs/components/helpers/dates';
import { updateKeys } from '../../../../components/treeStructure/treeHelper';
import { setElementClass } from '../../../helpers/setElementClass';
import DocumentDates from './DocumentDates';
import DocumentRights from './DocumentRights';

const DocumentTreeRow = ({
  folder,
  elementId,
  showAction,
  userIsAdmin,
  refRow,
  args,
  offerStatus,
  userCanEditDocuments,
  originalDocuments,
  setDocuments,
  refetch,
  setRefetchWidgets,
  isPublished,
  teams,
  userTeam,
  setIsLoadingOfferItem,
  setFullscreenLoading,
  setRefetchTags
}) => {
  const { offerId, scope } = args;
  const { removeTree, expandedKeys, setExpandedKeys, trees } = useTreeContext();
  const { documentPreviewUrl, deleteRawDocument } = useDocumentsTable();

  const currentDocumentSelected = find(propEq('id', elementId))(
    originalDocuments
  );

  const documentRights = prop('rights', currentDocumentSelected);
  const {
    previewDocumentHtmlOnReview,
    previewHtmlOnReview,
    documentIdModalToShow,
    setPreviewHtmlOnReview
  } = useDocument(currentDocumentSelected, { scope, offerId });

  const commentsHandlers = useComments();
  const element = prop('element', folder);
  const documentName = prop('name', element);
  const isRawDocument = propEq('type', 'rawDocument')(element);
  const hasParentFolder = allPass([
    propSatisfies(isNotNil, 'parent'),
    always(elementId)
  ])(folder);
  const docIsNotEditable =
    includes(offerStatus, ['review', 'release']) && !isRawDocument;

  const handleDeleteRawDocument = () => {
    if (isNil(prop('treeId', element))) {
      const nodeKey = compose(
        path([0, 'key']),
        filter(pathEq(['element', 'elmId'], elementId))
      )(trees);

      const updatedKeys = updateKeys(expandedKeys, [nodeKey], 'remove');

      setExpandedKeys(updatedKeys);
      deleteRawDocument(elementId, (ok) => {
        if (ok) setDocuments(filter(propNotEq('id', elementId)));
      });
    } else {
      removeTree(prop('treeId', element), folder);
      deleteRawDocument(prop('elmId', element), (ok) => {
        if (ok) setDocuments(filter(propNotEq('id', prop('elmId', element))));
      });
    }
  };

  const handleDeleteFromDirectory = () => {
    removeTree(prop('treeId', element), folder, true);
  };

  useEffect(() => {
    if (documentPreviewUrl) {
      window.open(documentPreviewUrl);
    }
  }, [documentPreviewUrl]);

  const linkToDocument = cond([
    [() => originalDocuments && !isRawDocument, always(`/offre/${elementId}/editer`)],
    [() => isRawDocument, always(`/files/${elementId}`)],
    [
      () =>
        and(userCanEditDocuments, userIsAdmin) ||
        notEqual(offerStatus, 'review'),
      always(`/offre/${offerId}/documents-importes/${elementId}/edition`)
    ]
  ])();

  const handleHidePreview = () => {
    setPreviewHtmlOnReview(undefined);
    hideComments();
  };

  const canRemoveFromFolder = hasParentFolder && userCanEditDocuments && userIsAdmin;

  return (
    <>
      <Col ref={refRow}>
        <Link to={linkToDocument} target={isRawDocument ? '_blank' : null}>
          {documentName}
        </Link>
        {docIsNotEditable && (
          <>
            {anyPass([
              prop('userIsDocumentEditor'),
              prop('userIsDocumentValidator')
            ])(documentRights) && (
              <>
                <QComments args={{ itemRootId: elementId }}>
                  {(data) => (
                    <CommentsWrapper
                      comments={propOr([], 'comments', data)}
                      mode={
                        offerStatus === 'review' ? 'review' : 'edition'
                      }
                      handlers={commentsHandlers}
                      itemRootId={elementId}
                    >
                      <ModalPreviewDocument
                        html={previewHtmlOnReview}
                        hide={handleHidePreview}
                        visible={
                          equals(documentIdModalToShow, elementId) &&
                                isNotNil(previewHtmlOnReview)
                        }
                        isReview={
                          offerStatus === 'review' &&
                                documentRights.userIsDocumentValidator
                        }
                        offerId={offerId}
                        documentId={elementId}
                        document={currentDocumentSelected}
                      />
                    </CommentsWrapper>
                  )}
                </QComments>

                <Tooltip title="Voir le document" color="#0197DC">
                  <EyeOutlined
                    onClick={previewDocumentHtmlOnReview}
                    className={classNames('text-black hover:text-flexibranche-darkgray opacity-0', { 'opacity-100': showAction })}
                  />
                </Tooltip>
              </>
            )}
          </>
        )}
      </Col>
      <Col>
        <Row gutter={6} className={`opacity-${showAction ? '100' : '0'}`}>
          <Col>
            <Space>
              {canRemoveFromFolder && (
                <Tooltip
                  title="Retirer le document de l'arborescence"
                  color="#0197DC"
                >
                  <CloseOutlined
                    onClick={handleDeleteFromDirectory}
                    className="text-black hover:text-flexibranche-darkgray btn-remove-item-root"
                  />
                </Tooltip>
              )}

              {isRawDocument ? (
                <>
                  {userCanEditDocuments && userIsAdmin && (
                    <>
                      <Tooltip
                        title="Modifier le nom du document"
                        color="#0197DC"
                      >
                        <Link
                          to={`documents-importes/${elementId}/edition`}
                          className="text-black hover:text-flexibranche-darkgray"
                        >
                          <FormOutlined />
                        </Link>
                      </Tooltip>
                      <Popover
                        trigger="click"
                        title=""
                        placement="bottom"
                        content={(
                          <SelectTags
                            offerId={offerId}
                            documentId={elementId}
                          />
                        )}
                      >
                        <Tooltip title="Lier à un tag" color="#0197DC">
                          <TagsOutlined className="hover:text-flexibranche-darkgray" />
                        </Tooltip>
                      </Popover>
                      <Popconfirm
                        placement="left"
                        title="Êtes-vous sûr de vouloir supprimer ce document ?"
                        onConfirm={handleDeleteRawDocument}
                      >
                        <Tooltip title="Supprimer le document" color="#0197DC">
                          <DeleteOutlined className={`${setElementClass(documentName, 'delete-document-button')} hover:text-red-300`} />
                        </Tooltip>
                      </Popconfirm>
                    </>
                  )}
                </>
              ) : (
                <DocumentRights
                  documentRights={defaultTo({}, documentRights)}
                  doc={element}
                  setDocuments={setDocuments}
                  offerId={offerId}
                  userIsAdmin={userIsAdmin}
                  userCanEditDocuments={userCanEditDocuments}
                  folder={folder}
                  refetch={refetch}
                  setRefetchWidgets={setRefetchWidgets}
                  isPublished={isPublished}
                  documentName={documentName}
                  teams={teams}
                  userTeam={userTeam}
                  setIsLoadingOfferItem={setIsLoadingOfferItem}
                  setFullscreenLoading={setFullscreenLoading}
                  setRefetchTags={setRefetchTags}
                />
              )}
            </Space>
          </Col>

          {element && element.dates && (
            <DocumentDates renderDate={renderDate} element={element} />
          )}
        </Row>
      </Col>
    </>
  );
};

export default DocumentTreeRow;
