import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { PlusOutlined, FormOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { notEqual } from 'ramda-adjunct';
import { any, assoc, compose, defaultTo, find, head, isNil, length, path, pathEq, prop, propEq, propOr, reject, sortBy, toLower } from 'ramda';
import RemoveUserFromTeamMutation from '../../../_graphql/mutations/RemoveUserFromTeamMutation';
import ModalInviteMembers from '../modals/ModalInviteMembers';
import ModalCreateTeam from '../modals/ModalCreateTeam';
import CreateTeamMutation from '../../../_graphql/mutations/teams/CreateTeamMutation';
import { Section, Title, Actions } from '../../../components';
import Loading from '../../../components/Loading';
import GroupsTable from '../../groups/GroupsTable';
import Elements from '../../elements/Elements';
import useApiKey from '../../../hooks/useApiKey';
import { QGroupsQuery } from '../../../_graphql/queries/groups/QGroups';
import useFetchQuery from '../../../hooks/useFetchQuery';
import { errorNotification, successMessage, successNotification, errorMessage } from '../../../utils/messageMutation';
import UpdateTeamNameMutation from '../../../_graphql/mutations/teams/UpdateTeamNameMutation';
import LeaveTeamMutation from '../../../_graphql/mutations/teams/LeaveTeamMutation';
import DefineActiveTeamMutation from '../../../_graphql/mutations/teams/DefineActiveTeamMutation';
import withUser from '../../../withUser';
import DeleteTeamMutation from '../../../_graphql/mutations/teams/DeleteTeamMutation';
import RemoveAllByTeamIdMutation from '../../../_graphql/mutations/offers/RemoveAllByTeamIdMutation';
import FullscreenLoading from '../../../components/FullscreenLoading';
import GenericModalTeam from '../modals/GenericModalTeam';
import TeamApiKey from './TeamApiKey';
import EmailsConfig from './EmailsConfig';
import MembersTable from './MembersTable';

const useMyTeam = (user, userTeam, userRefetch, teams) => {
  const { groups, reFetch: reFetchGroups, isLoading, error } = useFetchQuery({
    query: QGroupsQuery,
    dataProp: 'groups',
    defaultData: []
  });

  const history = useHistory();
  const [showModals, setShowModals] = useState({
    inviteMembers: false,
    createTeam: false,
    leaveTeam: false,
    deleteTeam: false
  });
  const [actionLoading, setActionLoading] = useState(false);
  const hideModal = modal => () => setShowModals(assoc(modal, false));
  const showModal = modal => () => setShowModals(assoc(modal, true));

  const nbOfMembers = compose(
    length,
    propOr([], 'members')
  )(userTeam);

  const onRemoveUser = userId => () => {
    RemoveUserFromTeamMutation({ teamId: prop('teamId')(userTeam), userId }, (ok, error) => {
      if (ok && !error) {
        userRefetch();
        successNotification('L\'utilisateur a été retiré de l\'équipe.');
      } else {
        errorNotification();
      }
    });
  };

  const onCreateTeam = teamName => {
    CreateTeamMutation({ name: teamName, userId: prop('userId', user) }, (ok, error) => {
      if (ok && !error) {
        reFetchGroups();
        userRefetch();
        setShowModals({
          inviteMembers: true,
          createTeam: false
        });
        successNotification('L\'équipe a été créée !', 'Vous pouvez maintenant inviter vos collaborateurs.');
      } else {
        errorNotification();
      }
    });
  };

  const onDefineActiveTeam = () => {
    const currentTeamId = prop('teamId')(userTeam);
    const filteredTeams = reject(propEq('id', currentTeamId))(teams);

    const team = head(sortBy(
      compose(
        toLower,
        prop('name')
      )
    )(filteredTeams));

    const callback = (redictToTeamCreation = false) => {
      userRefetch();
      history.push('/empty');
      history.replace(redictToTeamCreation ? '/equipe' : '/');
    };

    if (isNil(team)) {
      callback(true);
    } else {
      const { id: teamId, name: teamName, apiKey } = team;
      DefineActiveTeamMutation({
        teamId, teamName, userId: prop('userId', user), apiKey
      }, (ok, error) => {
        if (!ok || error) errorMessage();
        callback();
      });
    }
  };

  const onLeaveTeam = () => {
    hideModal('leaveTeam')();
    setActionLoading(true);
    LeaveTeamMutation({}, (ok, error) => {
      if (ok && !error) {
        successNotification('Vous avez bien quitté l\'équipe !');
        onDefineActiveTeam();
      } else {
        errorNotification();
      }
      setActionLoading(false);
    });
  };

  const onDeleteTeam = () => {
    hideModal('deleteTeam')();
    setActionLoading(true);
    DeleteTeamMutation({}, (ok, error) => {
      if (ok && !error) {
        RemoveAllByTeamIdMutation({}, (ok, error) => {
          if (ok && !error) {
            successNotification('L\'équipe a été supprimée avec succès !');
            onDefineActiveTeam();
          } else {
            errorNotification();
          }
          setActionLoading(false);
        });
      } else {
        errorNotification();
        setActionLoading(false);
      }
    });
  };

  return {
    nbOfMembers,
    showModals,
    userTeam,
    onRemoveUser,
    onCreateTeam,
    onLeaveTeam,
    onDeleteTeam,
    hideModal,
    showModal,
    groups,
    isLoading,
    error,
    actionLoading,
    setActionLoading
  };
};

const MyTeam = ({
  user,
  userTeam,
  userIsAdmin,
  userRefetch,
  teams
}) => {
  const history = useHistory();
  const {
    nbOfMembers,
    showModals,
    hideModal,
    showModal,
    onRemoveUser,
    onCreateTeam,
    onLeaveTeam,
    onDeleteTeam,
    groups,
    isLoading,
    error,
    actionLoading
  } = useMyTeam(user, userTeam, userRefetch, teams);
  const { teamApiKey } = useApiKey(teams, userTeam);
  const [focusOfferName, setFocusOfferName] = useState(false);
  const teamNameRef = useRef();

  const { canLeave, canDelete } = useMemo(() => {
    const members = prop('members')(userTeam);

    const isCurrentUser = pathEq(['user', 'id'], prop('userId')(user));
    const isAdmin = compose(
      propEq('role', 'admin'),
      defaultTo({})
    );

    const currentUserIdAdmin = compose(isAdmin, find(isCurrentUser))(members);

    const hasOtherAdmin = any(
      (member) => isAdmin(member) && !isCurrentUser(member)
    )(members);

    return {
      canLeave: hasOtherAdmin,
      canDelete: currentUserIdAdmin && !hasOtherAdmin
    };
  }, [user, userTeam]);

  if (isLoading) return <Loading/>;
  if (error) return <p>Erreur</p>;

  const teamName = prop('name')(userTeam);
  const userId = prop('userId')(user);

  const onBlurTeamName = () => {
    const newTeamName = path(['current', 'innerText'], teamNameRef);
    if (notEqual(teamName, newTeamName)) {
      UpdateTeamNameMutation({ name: newTeamName }, (ok, error) => {
        if (ok && !error) {
          successMessage('nom de l\'équipe', 'modifié', false, false);
          userRefetch();
        } else {
          errorMessage();
        }
      });
    }
    setFocusOfferName(false);
  };

  const onKeyDownTeamName = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  const teamNameInput = (
    <>
      <span
        ref={teamNameRef}
        className="text-lg"
        suppressContentEditableWarning
        contentEditable={focusOfferName}
        spellCheck={false}
        onBlur={onBlurTeamName}
        onKeyDown={onKeyDownTeamName}
      >
        {teamName}
      </span>
      {userIsAdmin && (
        <Tooltip title="Éditer" color="#0197DC">
          <FormOutlined
            onClick={() => {
              setFocusOfferName(true);
              teamNameRef.current.focus();
            }}
            className="hover:text-flexibranche-darkgray pl-2"
          />
        </Tooltip>
      )}
    </>
  );

  return (
    <>
      {actionLoading && <FullscreenLoading />}

      <Actions
        title={<Title title="gérer mon équipe" icon="/icon/icon-equipe-dark.svg" />}
        buttons={(
          <Button
            type="primary"
            onClick={showModal('createTeam')}
            size="large"
          >
            Créer une équipe
          </Button>
        )}
      />
      <Section
        title="Équipe :"
        optionTitle={teamNameInput}
        actions={(
          <>
            {userIsAdmin && (
              <Button size="large" onClick={showModal('inviteMembers')}>
                Inviter un collaborateur
              </Button>
            )}
            <Button
              danger size="large" disabled={!canLeave}
              onClick={canLeave && showModal('leaveTeam')}
            >
              Quitter l&apos;équipe
            </Button>
            <Button
              danger size="large" disabled={!canDelete}
              onClick={canDelete && showModal('deleteTeam')}
            >
              Supprimer l&apos;équipe
            </Button>
          </>
        )}
      >
        <ModalInviteMembers
          team={userTeam}
          onCancel={hideModal('inviteMembers')}
          visible={prop('inviteMembers', showModals)}
        />
        <ModalCreateTeam
          onCancel={hideModal('createTeam')}
          visible={prop('createTeam', showModals)}
          onCreateTeam={onCreateTeam}
        />
        <GenericModalTeam
          type="leave"
          onCancel={hideModal('leaveTeam')}
          visible={prop('leaveTeam', showModals)}
          teamName={teamName}
          callback={onLeaveTeam}
        />
        <GenericModalTeam
          type="delete"
          onCancel={hideModal('deleteTeam')}
          visible={prop('deleteTeam', showModals)}
          teamName={teamName}
          callback={onDeleteTeam}
        />

        <div className="mb-6 text-flexibranche-darkgray text-sm font-thin">
          {nbOfMembers} {nbOfMembers > 1 ? 'membres' : 'membre'}
        </div>
        <MembersTable
          members={propOr([], 'members', userTeam)}
          userTeam={userTeam}
          onRemoveUser={onRemoveUser}
          userIsAdmin={userIsAdmin}
          userId={userId}
        />
      </Section>

      <Section
        title="Gestion des groupes"
        actions={userIsAdmin && (
          <Button
            onClick={() => history.push('/equipe/groupes/creer')}
            icon={<PlusOutlined />}
            size="large"
          >
            Créer un groupe
          </Button>
        )}
      >
        <GroupsTable groups={groups} />
      </Section>

      <EmailsConfig userTeam={userTeam} />

      <Elements />

      <TeamApiKey apiKey={teamApiKey} />
    </>
  );
};

MyTeam.propTypes = { user: PropTypes.object.isRequired };

export default withUser(MyTeam);
