import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QDefaultFontQuery = graphql`
  query QDefaultFontQuery($teamId: ID!) {
    defaultFont(teamId: $teamId) {
      id
      teamId
      value
    }
  }
`;

const QDefaultFont = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    args={args}
    query={QDefaultFontQuery}
  >
    {populateChildren('defaultFont')(children, childDataProp)}
  </Query>
);

export default QDefaultFont;
