import { equals, has, is, isNil } from 'ramda';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';

const QueryRun = ({
  children,
  query,
  queryReference
}) => {
  const data = usePreloadedQuery(query, queryReference);

  if (!data) {
    return <div>Erreur lors du chargement des données.</div>;
  }

  if (is(Function, children)) return children(data);
  return React.cloneElement(
    children,
    data
  );
};

QueryRun.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  query: PropTypes.object.isRequired,
  queryReference: PropTypes.object.isRequired
};

const Query = ({
  query,
  args,
  children,
  fetchPolicy = 'store-and-network',
  isCompareFrame
}) => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query);
  const argsRef = useRef();

  const memoizedArgs = useMemo(() => args, [args]);
  const memoizedFetchPolicy = useMemo(() => fetchPolicy, [fetchPolicy]);
  const memoizedLoadQuery = useCallback((args, options) => loadQuery(args, options), [loadQuery]);

  useEffect(() => disposeQuery, [disposeQuery]);

  useEffect(() => {
    if (isCompareFrame) {
      if (equals(argsRef.current, memoizedArgs)) return;
      argsRef.current = memoizedArgs;
      memoizedLoadQuery(memoizedArgs, { fetchPolicy: memoizedFetchPolicy });
    } else {
      memoizedLoadQuery(memoizedArgs, { fetchPolicy: memoizedFetchPolicy });
    }
  }, [memoizedArgs, memoizedFetchPolicy, memoizedLoadQuery, isCompareFrame]);

  useEffect(() => {
    if (has('refetch', memoizedArgs) && has('setRefetch', memoizedArgs) && memoizedArgs.refetch) {
      memoizedArgs.setRefetch(false);
      memoizedLoadQuery(memoizedArgs, { fetchPolicy: memoizedFetchPolicy });
    }
  }, [memoizedArgs, memoizedFetchPolicy, memoizedLoadQuery]);

  if (isNil(queryReference)) return null;

  return (
    <React.Suspense fallback={<Loading />}>
      <QueryRun query={query} queryReference={queryReference}>
        {children}
      </QueryRun>
    </React.Suspense>
  );
};

Query.propTypes = {
  query: PropTypes.object.isRequired,
  args: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  fetchPolicy: PropTypes.string,
  isCompareFrame: PropTypes.bool
};

export default Query;
