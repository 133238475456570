import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

export const QOfferMetadataQuery = graphql`
  query QOfferMetadataQuery($offerId: ID!, $bundleTag: String!) {
    offerMetadata(offerId: $offerId, bundleTag: $bundleTag ) {
      ok
      error
      data {
        type
        key
        dynamicTableData {
          colsToHide
          rows {
            rowName
            cells {
              value
            }
          }
        }
        dataMatrix
        options {
          value
          title
        }
        default {
          type
          value
        }
      }

    }
  }
`;

const QOfferMetadata = ({
  children,
  args,
  childDataProp
}) => (
  <Query
    query={QOfferMetadataQuery}
    args={args}
  >
    {populateChildren('offer')(children, childDataProp)}
  </Query>
);

export default QOfferMetadata;
