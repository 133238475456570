/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type QTagsQueryVariables = {|
  scope: DocumentScopeEnum,
  offerId?: ?string,
  itemType?: ?string,
|};
export type QTagsQueryResponse = {|
  +tags: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +itemType: ?string,
    +itemsIds: ?$ReadOnlyArray<?string>,
    +color: ?string,
    +isDefault: ?boolean,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
  |}>
|};
export type QTagsQuery = {|
  variables: QTagsQueryVariables,
  response: QTagsQueryResponse,
|};
*/


/*
query QTagsQuery(
  $scope: DocumentScopeEnum!
  $offerId: ID
  $itemType: String
) {
  tags(scope: $scope, offerId: $offerId, itemType: $itemType) {
    id
    name
    itemType
    itemsIds
    color
    isDefault
    dates {
      creation
      lastUpdate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "itemType",
        "variableName": "itemType"
      },
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      },
      {
        "kind": "Variable",
        "name": "scope",
        "variableName": "scope"
      }
    ],
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "tags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itemsIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDefault",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Dates",
        "kind": "LinkedField",
        "name": "dates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QTagsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QTagsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b6cf56cf4afbfc0b4029f36d5dead53d",
    "id": null,
    "metadata": {},
    "name": "QTagsQuery",
    "operationKind": "query",
    "text": "query QTagsQuery(\n  $scope: DocumentScopeEnum!\n  $offerId: ID\n  $itemType: String\n) {\n  tags(scope: $scope, offerId: $offerId, itemType: $itemType) {\n    id\n    name\n    itemType\n    itemsIds\n    color\n    isDefault\n    dates {\n      creation\n      lastUpdate\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4981b8ebf6d6c61d0660594ca31ec14d';

module.exports = node;
