import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '../../index';
import { populateChildren } from '../toolbox';

const QColorQuery = graphql`
  query QColorQuery($colorId: ID!) {
    color(colorId: $colorId) {
      id
      name
      value
    }
  }
`;

const QColor = ({
  args,
  children,
  childDataProp
}) => (
  <Query
    args={args}
    query={QColorQuery}
  >
    {populateChildren('color')(children, childDataProp)}
  </Query>
);

export default QColor;
