/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentScopeEnum = "global" | "offer" | "%future added value";
export type OrientationEnum = "landscape" | "portrait" | "%future added value";
export type WidgetInput = {|
  contents?: ?string,
  frameStyle?: ?FrameStyleInput,
  gridRowCorrected?: ?boolean,
  heightRowsGrid?: ?number,
  id?: ?string,
  name?: ?string,
  nbColsGrid?: ?number,
  offerId?: ?string,
  orientation?: ?OrientationEnum,
  rootWidgetId?: ?string,
  scope?: ?DocumentScopeEnum,
|};
export type FrameStyleInput = {|
  backgroundColor?: ?string,
  borderColor?: ?BorderColorAndStyleInput,
  borderRadius?: ?BorderRadiusInput,
  borderStyle?: ?BorderColorAndStyleInput,
  borderWidth?: ?BorderWidthInput,
|};
export type BorderColorAndStyleInput = {|
  bottom?: ?string,
  left?: ?string,
  right?: ?string,
  top?: ?string,
|};
export type BorderRadiusInput = {|
  bottomLeft?: ?number,
  bottomRight?: ?number,
  topLeft?: ?number,
  topRight?: ?number,
|};
export type BorderWidthInput = {|
  bottom?: ?number,
  left?: ?number,
  right?: ?number,
  top?: ?number,
|};
export type CreateWidgetMutationVariables = {|
  widget: WidgetInput
|};
export type CreateWidgetMutationResponse = {|
  +createWidget: ?{|
    +ok: boolean,
    +error: ?string,
    +widgetId: ?string,
  |}
|};
export type CreateWidgetMutation = {|
  variables: CreateWidgetMutationVariables,
  response: CreateWidgetMutationResponse,
|};
*/


/*
mutation CreateWidgetMutation(
  $widget: WidgetInput!
) {
  createWidget(widget: $widget) {
    ok
    error
    widgetId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "widget"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "widget",
        "variableName": "widget"
      }
    ],
    "concreteType": "CreateOrUpdateWidgetMutation",
    "kind": "LinkedField",
    "name": "createWidget",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "widgetId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateWidgetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateWidgetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e0fd34836041771e0f251431e013f41",
    "id": null,
    "metadata": {},
    "name": "CreateWidgetMutation",
    "operationKind": "mutation",
    "text": "mutation CreateWidgetMutation(\n  $widget: WidgetInput!\n) {\n  createWidget(widget: $widget) {\n    ok\n    error\n    widgetId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dab42fad10563211737e6430b7e37451';

module.exports = node;
